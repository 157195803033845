/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';

export const faqDataCommon = [
  {
    q: 'How to use the website feedback tool? ',
    a: (
      <>
        <a
          href="https://web.ruttl.com/signup"
          target="_blank"
          rel="noopener noreferrer">
          Sign up
        </a>
        &nbsp;and create a project, then paste your website URL inside this
        project. Invite your teammates as collaborators by adding their email
        addresses. Once the page loads on ruttl, click to add comments, tag
        other collaborators in comments using ‘@’, and resolve or delete
        comments. You can also add video comments. Switch to edit mode to move
        elements, replace images, edit text, change background colours, modify
        opacities, etc. These edits can be resolved once done, speeding up your
        web review process.
      </>
    ),
  },
  {
    q: 'Is the website feedback project available for free?',
    a: (
      <>
        Yes, your first project is on us. You can invite your team members to
        join this project and start collaborating together to review websites.
      </>
    ),
  },
  {
    q: 'Do clients need to have an account on ruttl to view projects?',
    a: 'Absolutely not! Once your project is ready to share, simply click the Share button on the project page. In the new screen, click on the ‘Copy link’ text. The shareability toggle should be turned on and the link will be copied to your clipboard. Now all you need to do is share the link with your clients to get their feedback as comments on your project(s).',
  },

  {
    q: 'How many collaborators can I add to a project?',
    a: (
      <>
        In the free version, you have the ability to add up to 5 collaborators
        on a project. However, the limit of collaborators changes as per the
        paid plan that you upgrade to. For more details, check out our
        <Link to="/pricing/">&nbsp;pricing plans</Link>
        &nbsp;.
      </>
    ),
  },
  {
    q: 'Are there any integrations available in ruttl?',
    a: (
      <>
        Currently we have integrated the following - Jira, Slack, Trello,
        ClickUp, Zapier, asana and Unsplash. We also plan to integrate soon with
        many more tools. For more details, please visit our
        <Link to="/integrations/">&nbsp;integrations&nbsp;</Link>
        page.
      </>
    ),
  },

  {
    q: 'Is there a public roadmap available?',
    a: (
      <>
        Yes, we have our public roadmap. Check out our
        <a
          href="https://trello.com/b/D9T51EZX"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;Trello board&nbsp;
        </a>
        for the same.
      </>
    ),
  },
  {
    q: 'Can developers access website code after design changes are executed?',
    a: ' After the edits are saved, your developer(s) can see them inside the Activity Panel with precise changes. To make the required changes to your website design, your developers can simply copy the entire altered code under the CSS tab. This can then be pasted directly into the code of live/staging website files.',
  },
  {
    q: 'Why isn’t my website loading properly in the project panel?',
    a: (
      <>
        This issue may occur rarely, In such a scenario, we’d request you to try
        loading your site using our web app project. If the issue persists,
        please reach out to us at
        <a
          href="mailto:support@ruttl.com"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;support@ruttl.com
        </a>
      </>
    ),
  },
  {
    q: 'Which websites does ruttl work with?',
    a: 'Our website projects work with static, WordPress, Vue.js, and React sites for collaborating on visual website feedback. In fact, you can use it to work on pretty much every website. Just paste the website URL and get started!',
  },
  // {
  //   q:
  //     'Can I review static webpage design images with my team on ruttl?',
  //   a:
  //     'Absolutely. Simply upload the image, and your team can instantly start leaving comments. The best part - even clients can leave comments - without the hassles of login!',
  // },
  {
    q: 'Do you support task management inside your platform?',
    a: (
      <>
        No, ruttl does not support task management. If you’re looking for the
        same, we would recommend checking out
        <a href="https://brutask.com" target="_blank" rel="noopener noreferrer">
          &nbsp;Brutask
        </a>
        . It is a simple online to-do list for individuals and small teams. Add,
        prioritize and time box your tasks to help improve your productivity.
      </>
    ),
  },

  {
    q: 'I accidentally deleted my project, is there a way to recover the same?',
    a: 'Deleting projects on ruttl is permanent and there’s no way to recover the same, hence we’d recommend deleting projects (or pages/files) with utmost precaution, and only when you’re absolutely sure about the same.',
  },
  {
    q: 'How do I upgrade my ruttl account? ',
    a: (
      <>
        Please visit the
        <a
          href="https://web.ruttl.com/upgrade"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;Upgrade Account&nbsp;
        </a>
        section. Here you can choose the plan that suits you best, select the
        plan in monthly/annual mode and make the payment. Your account will be
        upgraded once we successfully receive your payment.
      </>
    ),
  },
  {
    q: 'Can I get a refund on my paid plan? ',
    a: 'If you have upgraded to any of our annual plans within the last 7 days, you are eligible to get a full refund. There is no refund for monthly plans.',
  },
  {
    q: 'Can I switch between plans before they end?',
    a: 'Yes, it’s possible to switch but you can only upgrade your plan. Just select the plan, make the payment and your plan should be upgraded once we receive the payment',
  },
  {
    q: 'Will I get recommendations on my website’s look and feel?',
    a: (
      <>
        ruttl will not be able to recommend anything about your website’s look
        and feel. We would suggest you to please contact
        <a href="https://brucira.com" target="_blank" rel="noopener noreferrer">
          &nbsp;Brucira&nbsp;
        </a>
        by mailing us at
        <a
          href="mailto:hello@brucira.com."
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;hello@brucira.com.&nbsp;
        </a>
      </>
    ),
  },
  // {
  //   q: 'I only want my clients to review, not edit the website design — is this possible?',
  //   a: 'Of course! You can choose to share the guest link with your clients, and then they won’t be able to make any edits on the website design. This way, you can use our edit mode internally only with the ones whom you have added via email address and given edit access.',
  // },
  // {
  //   q: 'Does ruttl have a bug bounty program?',
  //   a:
  //     'We currently do not have a bug bounty program in place.',
  // },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

export const faqDatabugTracking = [
  {
    q: 'What is a bug tracking tool?',
    a: 'A bug tracking tool helps you to highlight bugs like broken images, wrong or non-working CTAs or hyperlinks while scrolling through the website.',
  },

  {
    q: 'How to use ruttl‘s bug tracking?',
    a: `Create a project and add your website or web app URL in the field. Once inside the project, copy the project’s snippet code & paste it before the </body> tag on every page of your website. The bug tracking plugin will sit on top of your website, so anyone with the link can do real-time commenting - no signup or fussy extensions needed!`,
  },
  {
    q: `Do I need to download ruttl's Chrome extension?`,
    a: (
      <>
        Our Chrome extension is only available as an alternative to the plugin
        method, and the extension can be downloaded from
        <a
          href="https://chrome.google.com/webstore/detail/ruttl/doecfodblgfnjmmphbedjgllipnbgbld"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;web store.
        </a>
      </>
    ),
  },
  {
    q: 'Is bug tracking available for free?',
    a: 'Yes, but in our free plan, your bug tracking project will only have a 2-day trial period.',
  },
  {
    q: 'Can I integrate Jira in bug tracking?',
    a: `Yes, you can send your tickets directly to Jira once the integration is enabled. Alternatively, you can also use the in-built workflow to organise and manage feedback within your project collaborators.
    `,
  },
  {
    q: 'How many collaborators can I add to a project?',
    a: 'On our Standard and Pro plans, you can add 10 and 20 collaborators respectively. The Enterprise plan comes with unlimited collaborators.',
  },
  {
    q: 'Is there a built-in workflow inside my bug tracking project?',
    a: 'Yes, you can use the Kanban-styled workflow to effectively manage the tracked bugs within your project collaborators.',
  },
  {
    q: 'Is mobile app feedback included in bug tracking?',
    a: 'Yes, our mobile app feedback is included in our bug tracking product.',
  },
  {
    q: 'How is bug tracking different from website feedback?',
    a: (
      <>
        Bug tracking involves tracking bugs on your website or product and
        adding them as tickets. Website feedback, on the other hand, involves
        adding comments and making edits to your website, wherein you can simply
        add the URL of your site, fetch all the URLs at once and start reviewing
        it with your project collaborators.
        <Link to="/website-feedback-tool">&nbsp;Know More</Link>
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

export const faqDataPdfAnnotation = [
  {
    q: 'How do I annotate PDF files on my dashboard?',
    a: 'Simply create a PDF project, upload the PDF/image inside the project & start adding comments. You can share the project link with external members to collect their comments without the need to sign-up.',
  },
  {
    q: 'Can I review my PDF or image with other people?',
    a: 'Absolutely, you simply need to add them as collaborators in that project, after which, these collaborators can add textual & video comments or even reply to comments to facilitate a quicker feedback process.',
  },
  {
    q: `I'm collaborating with other members on a PDF/image. Is it possible to add more reference materials?`,
    a: 'You can do so by adding attachments to comments, or adding video comments to make your feedback more contextual.',
  },
  {
    q: 'If I rework on a PDF/image, how do I review it with the one I created earlier?',
    a: 'With the help of versions, you can upload a reworked/updated file in the latest version, and also check the earlier version of the same file.',
  },
  {
    q: 'Can I edit PDF files using ruttl?',
    a: 'Not at the moment, but if we introduce it in the future, we shall surely notify all our users.',
  },
  {
    q: 'Are there any file limits while uploading PDF or image files?',
    a: 'Paid plan users can upload file sizes up to 50 MB, whereas our free plan users can only upload file sizes up to 2 MB.',
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

export const faqDataVideoAnnotation = [
  {
    q: 'What is a video annotation tool?',
    a: 'A video annotation tool allows users to add comments, timestamps, shapes, and other visual elements directly onto videos, making it easier to provide feedback and enhance collaboration.',
  },
  {
    q: 'How to annotate a video?',
    a: (
      <>
        To annotate a video with ruttl follow these steps:
        <ol>
          <li>Simply upload the video</li>
          <li>Click within the frame to add comments or shapes.</li>
          <li>
            Share the annotated video with others for collaborative feedback.
          </li>
        </ol>
      </>
    ),
  },
  {
    q: 'What are the features available in the video annotation tool?',
    a: 'The tool offers precise timestamp annotation, shape markup, screen recording, frame-accurate feedback, and seamless sharing for enhanced collaboration.',
  },
  {
    q: 'How do I share my annotated videos with others?',
    a: 'You can easily share your annotated videos by generating a project link and granting access to your team, clients, or collaborators with just a few clicks.',
  },
  {
    q: 'What file formats are supported for video uploads in this video annotation tool?',
    a: 'Our video annotation tool supports MP4 video file uploads, making it convenient for various video annotation needs.',
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

export const faqDataCustomerFeedback = [
  {
    q: 'How does ruttl’s customer feedback primarily help my support team?',
    a: (
      <>
        ruttl’s customer feedback helps you collect the following from your site
        visitors -
        <ol>
          <li>Live chat</li>
          <li>Issue reporting </li>
          <li>Feature requests</li>
          <li>Testimonials</li>
        </ol>
      </>
    ),
  },
  {
    q: 'How do I install this on my site?',
    a: 'Simply copy the ruttl project’s snippet and paste it before the </body> tag on every page of your website. In case of any queries or concerns, please reach out to us at support@ruttl.com',
  },
  {
    q: 'Can my users submit feature requests using ruttl’s customer feedback?',
    a: 'Yes, simply ask your users to click on ‘Request new feature’ from the live chat widget and follow the simple steps thereon.',
  },
  {
    q: 'Can my site visitors submit testimonials?',
    a: 'Absolutely, just ask them to click on ‘Give a testimonial’ from the live chat widget. Your visitors get options to submit their profile picture, company logo and testimonial content along with a star-rating.',
  },
  {
    q: 'Can I get my users’ network and console logs?',
    a: ' You can definitely check the right-side panel to access user details like browser, OS, browser version, screen resolution, etc.',
  },
  {
    q: 'I want to add white labelling to my plan, is it possible?',
    a: ' Once you upgrade to our Enterprise version, we shall enable the same for you as quickly as possible.',
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
