import React from 'react';

// import Section from './TrustedBy.styled';
import 'styles/components/TrustedBy.scss';
import { brandLogos, customerNumber } from '../../utils/contentProvider';

const TrustedBy = () => (
  <div className="trustedby-div trusted-by">
    <div className="">
      <div className="flex justify-content-between align-items-center">
        {brandLogos.map(({ name, image, id, width, height }) => (
          <div key={id} className="logo-image">
            <img
              loading="lazy"
              src={image}
              alt={name}
              title={name}
              width={width}
              height={height}
            />
          </div>
        ))}
      </div>
      <p className="center">
        More than&nbsp;
        {customerNumber}
        &nbsp;businesses, professionals and creators across the world trust
        ruttl with their digital products
      </p>
    </div>
  </div>
);

export default TrustedBy;
