import React, { useState, useEffect } from 'react';
import cn from 'clsx';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// import Section from './Faq.styled';
import { faqDataCommon } from '../../utils/contentProvider/faqDataCommon';
import { btnOnClick } from '../../utils/contentProvider/function';

import {
  MixPanelEvents,
  MixPanelButtons,
  MixPanelButtonContext,
  PostHogEvents,
  PostHogButtons,
  PostHogButtonContext,
} from '../../constants';
import 'styles/components/Faq.scss';

const NewFaq = ({ data, limitFaq, greyBg }) => {
  const [showFaq, setShowFaq] = useState(0);

  function showFaqClick() {
    setShowFaq((state) => !state);
  }

  useEffect(() => {
    if (limitFaq) {
      setShowFaq(1);
    }
  }, []);
  return (
    <section className={cn(['faq-section', { greyBg }])}>
      <div className="faq-home" id="faq">
        <div className="container">
          <div className="flex column align-items-center">
            <div className="faq-heading-area-new">
              <h2 className="center">Frequently Asked Questions</h2>
              {/* <p className="center">
                Here are some of the most frequent questions we get asked.
                <br />
                Got more questions? Visit our&nbsp;
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  help center
                </a>
              </p> */}
            </div>

            <div className="faq-question-main faq-question-main-new">
              <Accordion preExpanded={[0]} allowZeroExpanded>
                {(data || faqDataCommon).map(
                  ({ q, a, uuid = nanoid() }, id) => (
                    <AccordionItem
                      uuid={id}
                      key={uuid}
                      className={`faq-single ${
                        showFaq && id > 6 ? 'hidden-faq' : ''
                      }`}>
                      <AccordionItemHeading className="question">
                        <AccordionItemButton>
                          <h3>{q}</h3>
                          <svg
                            width="11"
                            height="7"
                            viewBox="0 0 11 7"
                            fill="none">
                            <path
                              d="m1 1 4.5 5L10 1"
                              stroke="#150546"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="answer">
                        {a}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ),
                )}
                <div className="flex justify-content-center">
                  <button
                    className={`button ${limitFaq ? '' : 'hidden'}`}
                    type="button"
                    onClick={btnOnClick({
                      mixPanelEvent: MixPanelEvents.ButtonClick,
                      posthogEvent: PostHogEvents.ButtonClick,
                      config: {
                        Button: MixPanelButtons.ViewMore,
                        Context: MixPanelButtonContext.FaqViewMore,
                      },
                      posthogConfig: {
                        Button: PostHogButtons.ViewMore,
                        Context: PostHogButtonContext.FaqViewMore,
                      },
                      callback: showFaqClick,
                    })}>
                    {showFaq ? 'View more' : 'View less'}
                  </button>
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
NewFaq.defaultProps = {
  data: null,
  limitFaq: null,
  //   headingCTA: false,
  //   toggleContactUs: () => {},
  greyBg: false,
};

NewFaq.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      q: PropTypes.string,
      a: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  limitFaq: PropTypes.bool,
  //   headingCTA: PropTypes.bool,
  //   toggleContactUs: PropTypes.func,
  greyBg: PropTypes.bool,
};

export default NewFaq;
